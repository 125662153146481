import { type ButtonPropsSize } from '@/tailwindComponents/Button/Button';
import ButtonIcon from '@/tailwindComponents/Button/ButtonIcon';
import ButtonText from '@/tailwindComponents/Button/ButtonText';
import { buttonCommonClassName } from '@/twComponents/common';
import Link, { type LinkProps } from 'next/link';
import type { ComponentProps } from 'react';

type ButtonProps = Omit<ComponentProps<'a'>, 'href'> &
  LinkProps & {
    size?: (typeof ButtonPropsSize)[number];
    type?: ComponentProps<'button'>['type'];
    disabled?: ComponentProps<'button'>['disabled'];
    icon?: JSX.Element;
    rIcon?: JSX.Element;
    text?: boolean;
  };

export default function ButtonLink({
  children,
  className,
  href,
  size,
  type,
  icon,
  disabled,
  rIcon,
  text,
  ...props
}: ButtonProps) {
  return (
    <Link
      href={href}
      className={buttonCommonClassName({ size, disabled, type, text, className })}
      {...props}
    >
      {icon && <ButtonIcon size={size}>{icon}</ButtonIcon>}
      {children && <ButtonText size={size}>{children}</ButtonText>}
      {rIcon && <ButtonIcon size={size}>{rIcon}</ButtonIcon>}
    </Link>
  );
}
