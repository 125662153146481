// src/app/_components/FatHeaderHover/FatHeaderHover.tsx

import React from 'react';
import Link from 'next/link';

const FatHeaderHover: React.FC = () => {
    return (
        <div className="absolute inset-x-0 top-full bg-white shadow-lg p-4 z-50">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                <div>
                    <h3 className="font-bold text-lg">Important Links</h3>
                    <ul className="text-sm">
                        <li><Link href="/sales-glossary">Sales Glossary</Link></li>
                        <li><Link href="https://support.useposeidon.com">More Resources and Guides</Link></li>
                        <li><Link href="/resources">Resources</Link></li>
                    </ul>
                </div>
                <div>
                    <h3 className="font-bold text-lg">Footer Content</h3>
                    <ul className="text-sm">
                        <li><Link href="/privacy-policy">Privacy Policy</Link></li>
                        <li><Link href="/terms-of-service">Terms of Service</Link></li>
                        <li><Link href="/contact-us">Contact Us</Link></li>
                    </ul>
                </div>
                <div>
                    <h3 className="font-bold text-lg">New Section</h3>
                    <ul className="text-sm">
                        <li><Link href="/about-us">About Us</Link></li>
                        <li><Link href="/careers">Careers</Link></li>
                        <li><Link href="/blog">Blog</Link></li>
                    </ul>
                </div>
                {/* Add more sections as needed */}
            </div>
        </div>
    );
};

export default FatHeaderHover;
