import { type ButtonProps } from '@/twComponents/Button/Button';
import ButtonDivider from '@/twComponents/Button/ButtonDivider';
import { buttonGroupButtonIconClassName } from '@/twComponents/Button/ButtonGroup';
import { buttonHeightAndBorder } from '@/twComponents/common';
import { cn } from '@/utils/cn';
import React, { type ComponentProps } from 'react';

type ButtonSegmentProps<ID> = Omit<ComponentProps<'div'>, 'onChange'> & {
  size?: ButtonProps['size'];
  disabled?: ButtonProps['disabled'];
  selectedId?: ID;
  onChange: (id: ID) => void;
};
export default function ButtonSegment<ID>({
  children,
  className,
  onChange,
  selectedId,
  disabled,
  size,
}: ButtonSegmentProps<ID>) {
  const filteredChildren: React.ReactElement<Omit<ButtonProps, 'id'> & { id: ID }>[] = [];
  React.Children.forEach(children, (child) => {
    if (child != null && React.isValidElement<Omit<ButtonProps, 'id'> & { id: ID }>(child)) {
      filteredChildren.push(child);
    }
  });

  if (filteredChildren.length == 0) return null;

  return (
    <div
      className={cn(
        'inline-flex overflow-hidden border',
        'border-slate-200 dark:border-slate-700',
        'bg-white dark:bg-slate-800',
        buttonHeightAndBorder(size),
        className,
      )}>
      {filteredChildren.map((child, i) => {
        return (
          <React.Fragment key={i}>
            {i > 0 && <ButtonDivider />}
            {React.cloneElement(child, {
              text: child.props.active || true,
              size,
              active: child.props.id === selectedId,
              disabled: child.props.disabled ?? disabled,
              className: cn(buttonGroupButtonIconClassName(), child.props.className),
              onClick: () => onChange(child.props.id),
            })}
          </React.Fragment>
        );
      })}
    </div>
  );
}
