import { type ButtonProps } from '@/twComponents/Button/Button';
import ButtonDivider from '@/twComponents/Button/ButtonDivider';
import { buttonHeightAndBorder } from '@/twComponents/common';
import { cn } from '@/utils/cn';
import React, { type ComponentProps } from 'react';

export function buttonGroupButtonIconClassName() {
  return 'rounded-none -mt-[1px] -mb-[1px] first:-ml-[1px] last:-mr-[1px]';
}

type ButtonGroupProps = ComponentProps<'div'> & {
  size?: ButtonProps['size'];
  disabled?: ButtonProps['disabled'];
};
export default function ButtonGroup({ children, className, disabled, size }: ButtonGroupProps) {
  const filteredChildren: React.ReactElement<ButtonProps>[] = [];
  React.Children.forEach(children, (child) => {
    if (child != null && React.isValidElement<ButtonProps>(child)) {
      filteredChildren.push(child);
    }
  });

  if (filteredChildren.length == 0) return null;

  return (
    <div
      className={cn(
        'inline-flex shrink-0 overflow-hidden border',
        'border-slate-200 dark:border-slate-700',
        'bg-white dark:bg-slate-800',
        buttonHeightAndBorder(size),
        className,
      )}>
      {filteredChildren.map((child, i) => {
        return (
          <React.Fragment key={i}>
            {i > 0 && <ButtonDivider />}
            {React.cloneElement(child, {
              text: child.props.active || true,
              size,
              disabled: child.props.disabled ?? disabled,
              className: cn(buttonGroupButtonIconClassName(), child.props.className),
            })}
          </React.Fragment>
        );
      })}
    </div>
  );
}
