import type { ButtonPropsSize } from '@/tailwindComponents/Button/Button';
import { cn } from '@/utils/cn';
import type { ComponentProps } from 'react';

type ButtonIconProps = {
  size?: (typeof ButtonPropsSize)[number];
  children: ComponentProps<'div'>['children'];
  className?: ComponentProps<'div'>['className'];
};
export default function ButtonIcon({ size, className, children }: ButtonIconProps) {
  return (
    <span
      className={cn(
        'flex aspect-square h-full items-center justify-center [&>svg]:h-full [&>svg]:w-auto',
        {
          'p-2.5': size == 'md',
          'p-2': size == null,
          'p-1.5': size == 'sm' || size == 'xs',
        },
        className,
      )}>
      {children}
    </span>
  );
}
