import ButtonIcon from '@/twComponents/Button/ButtonIcon';
import ButtonText from '@/twComponents/Button/ButtonText';
import { buttonCommonClassName, buttonHPadding } from '@/twComponents/common';
import { cn } from '@/utils/cn';
import type { ComponentProps } from 'react';

export const ButtonPropsSize = ['sm', 'xs', 'md'] as const;
export type ButtonProps<ID extends string = string> = ComponentProps<'button'> & {
  size?: (typeof ButtonPropsSize)[number];
  icon?: JSX.Element;
  rIcon?: JSX.Element;
  text?: boolean;
  active?: boolean;
  id?: ID;
  dot?: 'success' | 'error' | 'warning';
};

export default function Button<ID extends string = string>({
  children,
  className,
  disabled,
  type,
  size,
  icon,
  rIcon,
  text,
  active,
  dot,
  ...props
}: ButtonProps<ID>) {
  return (
    <button
      className={buttonCommonClassName({ size, disabled, type, active, text, className })}
      type={type}
      disabled={disabled}
      {...props}
    >
      {dot && (
        <div
          className={cn(
            {
              'pr-2': !icon && children && size == 'md',
              'pr-1.5': !icon && children && size == null,
              'pr-1': !icon && children && size == 'sm',
              'pr-0.5': !icon && children && size == 'xs',
            },
            'flex h-full items-center justify-center',
            buttonHPadding(size),
          )}
        >
          <div
            className={cn('rounded-full', {
              'size-2.5': size == 'md',
              'size-2': size == null,
              'size-1.5': size == 'sm',
              'size-1': size == 'xs',
              'bg-mantis-500': dot == 'success',
              'bg-fair-pink-500': dot == 'error',
              'bg-quarter-spanish-white-500': dot == 'warning',
            })}
          />
        </div>
      )}
      {icon && <ButtonIcon size={size}>{icon}</ButtonIcon>}
      {children && <ButtonText size={size}>{children}</ButtonText>}
      {rIcon && <ButtonIcon size={size}>{rIcon}</ButtonIcon>}
    </button>
  );
}
