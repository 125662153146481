import React, { type SVGAttributes } from 'react';

const strokeProps = {
  stroke: 'var(--stroke, currentColor)',
  strokeWidth: 'var(--stroke-width, 1.8)',
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
  fill: 'none',
} as const;

const fillProps = {
  fill: 'var(--fill, currentColor)',
  fillOpacity: 'var(--fill-opacity, 0.12)',
} as const;

const svgProps: SVGAttributes<SVGElement> = {
  viewBox: '0 0 24 24',
  width: '24px',
  height: '24px',
} as const;

export const User03: React.FC<React.HTMLProps<SVGElement>> = ({ className }) => {
  return (
    <svg className={className} {...svgProps}>
      <path
        d="M12 12C14.4853 12 16.5 9.98528 16.5 7.5C16.5 5.01472 14.4853 3 12 3C9.51472 3 7.5 5.01472 7.5 7.5C7.5 9.98528 9.51472 12 12 12Z"
        {...fillProps}
      />
      <path
        d="M3 20C5.33579 17.5226 8.50702 16 12 16C15.493 16 18.6642 17.5226 21 20M16.5 7.5C16.5 9.98528 14.4853 12 12 12C9.51472 12 7.5 9.98528 7.5 7.5C7.5 5.01472 9.51472 3 12 3C14.4853 3 16.5 5.01472 16.5 7.5Z"
        {...strokeProps}
      />
    </svg>
  );
};

export const TrendUp01: React.FC<React.HTMLProps<SVGElement>> = ({ className }) => {
  return (
    <svg className={className} {...svgProps}>
      <path
        d="M22 7L14.1314 14.8686C13.7354 15.2646 13.5373 15.4627 13.309 15.5368C13.1082 15.6021 12.8918 15.6021 12.691 15.5368C12.4627 15.4627 12.2646 15.2646 11.8686 14.8686L9.13137 12.1314C8.73535 11.7354 8.53735 11.5373 8.30902 11.4632C8.10817 11.3979 7.89183 11.3979 7.69098 11.4632C7.46265 11.5373 7.26465 11.7354 6.86863 12.1314L2 17M22 7H15M22 7V14"
        {...strokeProps}
      />
    </svg>
  );
};

export const Phone02: React.FC<React.HTMLProps<SVGElement>> = ({ className }) => {
  return (
    <svg className={className} {...svgProps}>
      <path
        d="M8.38064 8.85323C9.07664 10.3028 10.0254 11.6615 11.227 12.8631C12.4286 14.0646 13.7872 15.0134 15.2368 15.7094C15.3615 15.7693 15.4239 15.7992 15.5028 15.8222C15.7831 15.904 16.1274 15.8453 16.3648 15.6752C16.4316 15.6274 16.4888 15.5702 16.6031 15.4559C16.9527 15.1063 17.1275 14.9315 17.3033 14.8172C17.9662 14.3862 18.8208 14.3862 19.4836 14.8172C19.6594 14.9315 19.8342 15.1063 20.1838 15.4559L20.3787 15.6508C20.9101 16.1822 21.1759 16.448 21.3202 16.7333C21.6073 17.3009 21.6073 17.9712 21.3202 18.5387C21.1759 18.8241 20.9101 19.0898 20.3787 19.6213L20.2211 19.7789C19.6914 20.3085 19.4266 20.5733 19.0666 20.7756C18.6671 21 18.0466 21.1614 17.5884 21.16C17.1754 21.1588 16.8932 21.0787 16.3288 20.9185C13.2954 20.0575 10.433 18.433 8.04503 16.045C5.65705 13.6571 4.03257 10.7947 3.1716 7.76131C3.01139 7.19687 2.93129 6.91464 2.93006 6.5017C2.9287 6.04347 3.09006 5.42298 3.31448 5.02348C3.51673 4.66345 3.78154 4.39863 4.31116 3.86901L4.4688 3.71138C5.00024 3.17993 5.26596 2.91421 5.55134 2.76987C6.1189 2.4828 6.78917 2.4828 7.35673 2.76987C7.64211 2.91421 7.90783 3.17993 8.43927 3.71138L8.63414 3.90625C8.98375 4.25585 9.15855 4.43065 9.27284 4.60643C9.70383 5.26932 9.70383 6.1239 9.27284 6.78679C9.15855 6.96257 8.98375 7.13738 8.63414 7.48698C8.51983 7.60129 8.46268 7.65845 8.41483 7.72526C8.24482 7.96269 8.18612 8.30695 8.26785 8.5873C8.29084 8.6662 8.32078 8.72854 8.38064 8.85323Z"
        {...fillProps}
      />
      <path
        d="M8.38064 8.85323C9.07664 10.3028 10.0254 11.6615 11.227 12.8631C12.4286 14.0646 13.7872 15.0134 15.2368 15.7094C15.3615 15.7693 15.4239 15.7992 15.5028 15.8222C15.7831 15.904 16.1274 15.8453 16.3648 15.6752C16.4316 15.6274 16.4888 15.5702 16.6031 15.4559C16.9527 15.1063 17.1275 14.9315 17.3033 14.8172C17.9662 14.3862 18.8208 14.3862 19.4836 14.8172C19.6594 14.9315 19.8342 15.1063 20.1838 15.4559L20.3787 15.6508C20.9101 16.1822 21.1759 16.448 21.3202 16.7333C21.6073 17.3009 21.6073 17.9712 21.3202 18.5387C21.1759 18.8241 20.9101 19.0898 20.3787 19.6213L20.2211 19.7789C19.6914 20.3085 19.4266 20.5733 19.0666 20.7756C18.6671 21 18.0466 21.1614 17.5884 21.16C17.1754 21.1588 16.8932 21.0787 16.3288 20.9185C13.2954 20.0575 10.433 18.433 8.04503 16.045C5.65705 13.6571 4.03257 10.7947 3.1716 7.76131C3.01139 7.19687 2.93129 6.91464 2.93006 6.5017C2.9287 6.04347 3.09006 5.42298 3.31448 5.02348C3.51673 4.66345 3.78154 4.39863 4.31116 3.86901L4.4688 3.71138C5.00024 3.17993 5.26596 2.91421 5.55134 2.76987C6.1189 2.4828 6.78917 2.4828 7.35673 2.76987C7.64211 2.91421 7.90783 3.17993 8.43927 3.71138L8.63414 3.90625C8.98375 4.25585 9.15855 4.43065 9.27284 4.60643C9.70383 5.26932 9.70383 6.1239 9.27284 6.78679C9.15855 6.96257 8.98375 7.13738 8.63414 7.48698C8.51983 7.60129 8.46268 7.65845 8.41483 7.72526C8.24482 7.96269 8.18612 8.30695 8.26785 8.5873C8.29084 8.6662 8.32078 8.72854 8.38064 8.85323Z"
        {...strokeProps}
      />
    </svg>
  );
};
export const LightningIcon: React.FC<React.SVGProps<SVGElement>> = ({ className }) => {
  return (
    <svg {...svgProps} className={className}>
      <path
        d="M14.25 2H8.49444C8.31496 2 8.22522 2 8.146 2.02733C8.07593 2.05149 8.01212 2.09093 7.95917 2.14279C7.8993 2.20143 7.85916 2.2817 7.7789 2.44223L3.5789 10.8422C3.38722 11.2256 3.29138 11.4173 3.3144 11.5731C3.3345 11.7091 3.40976 11.8309 3.52246 11.9097C3.65153 12 3.86583 12 4.29444 12H10.5L7.50001 22L19.6931 9.35531C20.1045 8.9287 20.3102 8.7154 20.3222 8.53288C20.3326 8.37446 20.2672 8.22049 20.1459 8.11803C20.0062 8 19.7099 8 19.1172 8H12L14.25 2Z"
        {...fillProps}
      />
      <path
        d="M14.25 2H8.49444C8.31496 2 8.22522 2 8.146 2.02733C8.07593 2.05149 8.01212 2.09093 7.95917 2.14279C7.8993 2.20143 7.85916 2.2817 7.7789 2.44223L3.5789 10.8422C3.38722 11.2256 3.29138 11.4173 3.3144 11.5731C3.3345 11.7091 3.40976 11.8309 3.52246 11.9097C3.65153 12 3.86583 12 4.29444 12H10.5L7.50001 22L19.6931 9.35531C20.1045 8.9287 20.3102 8.7154 20.3222 8.53288C20.3326 8.37446 20.2672 8.22049 20.1459 8.11803C20.0062 8 19.7099 8 19.1172 8H12L14.25 2Z"
        {...strokeProps}
      />
    </svg>
  );
};

export const Lightbulb03: React.FC<React.HTMLProps<SVGElement>> = ({ className }) => {
  return (
    <svg className={className} {...svgProps}>
      <path
        d="M15 15.3264C17.3649 14.2029 19 11.7924 19 9C19 5.13401 15.866 2 12 2C8.13401 2 5 5.13401 5 9C5 11.7924 6.63505 14.2029 9 15.3264V16C9 16.9319 9 17.3978 9.15224 17.7654C9.35523 18.2554 9.74458 18.6448 10.2346 18.8478C10.6022 19 11.0681 19 12 19C12.9319 19 13.3978 19 13.7654 18.8478C14.2554 18.6448 14.6448 18.2554 14.8478 17.7654C15 17.3978 15 16.9319 15 16V15.3264Z"
        {...fillProps}
      />
      <path
        d="M9.5 22H14.5M15 15.3264C17.3649 14.2029 19 11.7924 19 9C19 5.13401 15.866 2 12 2C8.13401 2 5 5.13401 5 9C5 11.7924 6.63505 14.2029 9 15.3264V16C9 16.9319 9 17.3978 9.15224 17.7654C9.35523 18.2554 9.74458 18.6448 10.2346 18.8478C10.6022 19 11.0681 19 12 19C12.9319 19 13.3978 19 13.7654 18.8478C14.2554 18.6448 14.6448 18.2554 14.8478 17.7654C15 17.3978 15 16.9319 15 16V15.3264Z"
        {...strokeProps}
      />
    </svg>
  );
};

export const GraduationHat02: React.FC<React.HTMLProps<SVGElement>> = ({ className }) => {
  return (
    <svg className={className} {...svgProps}>
      <path
        d="M12.3578 3.67889C12.2266 3.6133 12.161 3.5805 12.0922 3.5676C12.0313 3.55616 11.9687 3.55616 11.9078 3.5676C11.839 3.5805 11.7734 3.6133 11.6422 3.67889L2 8.5L11.6422 13.3211C11.7734 13.3867 11.839 13.4195 11.9078 13.4324C11.9687 13.4438 12.0313 13.4438 12.0922 13.4324C12.161 13.4195 12.2266 13.3867 12.3578 13.3211L22 8.5L12.3578 3.67889Z"
        {...fillProps}
      />
      <path
        d="M17 14.5V11.4944C17 11.315 17 11.2252 16.9727 11.146C16.9485 11.0759 16.9091 11.0121 16.8572 10.9592C16.7986 10.8993 16.7183 10.8592 16.5578 10.7789L12 8.5M4 9.5V16.3066C4 16.6785 4 16.8645 4.05802 17.0273C4.10931 17.1712 4.1929 17.3015 4.30238 17.4081C4.42622 17.5287 4.59527 17.6062 4.93335 17.7611L11.3334 20.6945C11.5786 20.8069 11.7012 20.8631 11.8289 20.8852C11.9421 20.9049 12.0579 20.9049 12.1711 20.8852C12.2988 20.8631 12.4214 20.8069 12.6666 20.6945L19.0666 17.7611C19.4047 17.6062 19.5738 17.5287 19.6976 17.4081C19.8071 17.3015 19.8907 17.1712 19.942 17.0273C20 16.8645 20 16.6785 20 16.3066V9.5M2 8.5L11.6422 3.67889C11.7734 3.6133 11.839 3.5805 11.9078 3.5676C11.9687 3.55616 12.0313 3.55616 12.0922 3.5676C12.161 3.5805 12.2266 3.6133 12.3578 3.67889L22 8.5L12.3578 13.3211C12.2266 13.3867 12.161 13.4195 12.0922 13.4324C12.0313 13.4438 11.9687 13.4438 11.9078 13.4324C11.839 13.4195 11.7734 13.3867 11.6422 13.3211L2 8.5Z"
        {...strokeProps}
      />
    </svg>
  );
};

export const FileChekmark03: React.FC<React.HTMLProps<SVGElement>> = ({ className }) => {
  return (
    <svg {...svgProps} className={className}>
      <path
        d="M19.1213 7.12139L14.8787 2.87875C14.6222 2.62223 14.324 2.41696 14 2.26953V6.40007C14 6.96012 14 7.24015 14.109 7.45406C14.2049 7.64222 14.3578 7.7952 14.546 7.89108C14.7599 8.00007 15.0399 8.00007 15.6 8.00007H19.7305C19.5831 7.67611 19.3778 7.37792 19.1213 7.12139Z"
        {...fillProps}
      />
      <path
        d="M14 2.26953V6.40007C14 6.96012 14 7.24015 14.109 7.45406C14.2049 7.64222 14.3578 7.7952 14.546 7.89108C14.7599 8.00007 15.0399 8.00007 15.6 8.00007H19.7305M9 16L11 18L15.5 13.5M14 2H8.8C7.11984 2 6.27976 2 5.63803 2.32698C5.07354 2.6146 4.6146 3.07354 4.32698 3.63803C4 4.27976 4 5.11984 4 6.8V17.2C4 18.8802 4 19.7202 4.32698 20.362C4.6146 20.9265 5.07354 21.3854 5.63803 21.673C6.27976 22 7.11984 22 8.8 22H15.2C16.8802 22 17.7202 22 18.362 21.673C18.9265 21.3854 19.3854 20.9265 19.673 20.362C20 19.7202 20 18.8802 20 17.2V8L14 2Z"
        {...strokeProps}
      />
    </svg>
  );
};

export const Menu01: React.FC<React.SVGProps<SVGElement>> = ({ className }) => {
  return (
    <svg className={className} {...svgProps}>
      <path d="M3 12H21M3 6H21M3 18H21" {...strokeProps} />
    </svg>
  );
};

export const XMark: React.FC<React.HTMLProps<SVGElement>> = ({ className }) => {
  return (
    <svg className={className} {...svgProps}>
      <path d="M18 6L6 18M6 6L18 18" {...strokeProps} />
    </svg>
  );
};
